<template>
  <div class="container">
    <h1>Test QR</h1>
    <QRcodeScanner style="width: 500px" />
  </div>
</template>
<script>
import QRcodeScanner from "../components/QRcodeScanner.vue";
export default {
  name: "testqr",
  data() {
    return {};
  },
  components: {
    QRcodeScanner,
  },
  methods: {
    onScan(decodedText, decodedResult) {
      // handle the message here :)
      console.log(decodedText);
      console.log(decodedResult);
    },
  },
};
</script>
